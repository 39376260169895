html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  /* overflow:hidden */
}

#root {
  min-height: 100vh;
}

/* 
 .froscrolling {
scroll-snap-type: y mandatory;
&.proximity {
    scroll-snap-type: y proximity;
}
} */


.scroll-snap-y {
scroll-snap-type: y mandatory;
}

.scroll-snap-align-start {
scroll-snap-align: start;
}

@media screen and (max-width: 500px) {
.imageSet {
 background-position: 70%;
}
}